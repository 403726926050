.pageSpinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.inlineSpinner {
  position: relative;
  left: 50%;
}
