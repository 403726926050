#scrollUpArrow {
  position: fixed;
  bottom: 7vh;
  right: 3vw;
  font-size: 30px;
}
@media (max-width: 550px) {
  #scrollUpArrow {
    right: 2vw;
  }
}

.bounce {
  -moz-animation: bounce 3s infinite;
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@-moz-keyframes bounce {
  0%,
  50%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  50%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
