body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.DateRangePickerInput {
  font-size: 1rem;
}
.DateInput #endDate, .DateInput #startDate {
  font-weight: 400;
  padding: 5px 15px;
  font-size: min(3.5vw, 18px);
}

.DateRangePicker, .DateRangePickerInput__withBorder {
  width: 100%;
  height: 40px;
  border-radius: 0.25rem!important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DateRangePicker .DateInput {
  width: 46%;  
}

.DateRangePicker .DateInput #endDate {
  text-align: right;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #007BFF !important;
  border: none !important;
  color: #FFF !important;
}

.CalendarDay__selected_span {
  background: rgba(0, 123, 255, 0.3) !important;
  border: none !important;
  color: #212529 !important;
}

.CalendarDay__today {
  background: none !important;
  border: 2px solid #007BFF !important;
  color: #000 !important;
}

.CalendarDay__selected_span:hover {
  background: rgba(0, 123, 255, 0.6) !important;
  color: #000 !important;
}
