@import "../../form.css";

.dischargeFormContainer {
	min-width: 100px;
	margin-bottom: 4vh; /*Stops footer from overlapping*/
	padding: 4vh 20px;
	max-width: 960px;
}

.dischargeFormContainer .form-control,
.dischargeFormContainer .form-select,
.dischargeFormContainer .form-section-check,
.dischargeFormContainer .form-check {
	font-size: 70%;
}

.dischargeFormContainer .form-check-input {
	border: 1px solid #343a40;
}

.dischargeFormContainer .form-group {
	margin-bottom: 4vh;
}

@media only screen and (max-width: 700px) {
	.dischargeFormContainer .form-group {
		margin-bottom: 4vh;
	}

	.dischargeFormContainer .btn {
		font-size: calc(12px + 1vw);
	}

	.dischargeFormContainer .h5 {
		font-size: calc(100% + 2.3vw) !important;
	}
}
