@import "../../form.css";

.progressFormContainer {
  padding: 4vh 20px;
  min-width: 100px;
  max-width: 960px;
  margin-bottom: 4vh; /*Stops footer from overlapping*/
}

.progressFormContainer .form-control,
.progressFormContainer .form-select,
.progressFormContainer .form-section-check,
.progressFormContainer .form-check {
  font-size: 70%;
}

.progressFormContainer .form-check-input {
  border: 1px solid #343a40;
}

.progressFormContainer .form-group {
  margin-bottom: 4vh;
}

@media only screen and (max-width: 700px) {
  .progressFormContainer .form-group {
    margin-bottom: 4vh;
  }

  .progressFormContainer .btn {
    font-size: calc(12px + 1vw);
  }

  .progressFormContainer .h5 {
    font-size: calc(100% + 2.3vw) !important;
  }
}