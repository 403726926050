@import "../../form.css";

.transferFileContainer {
  min-width: 100px;
  margin-bottom: 4vh; /*Stops footer from overlapping*/
  padding: 4vh 2%;
  max-width: 960;
}

@media only screen and (max-width: 700px) {
  .formLabelText {
    margin-top: max(2vw, 6px) !important;
    margin-bottom: 0.5vh;
  }
}
