.bi-container {
  display: inline-block;
  vertical-align: -0.125em;
  font-size:90%;
}

.tooltipText {
  font-size: 70%;
}


.preserveWhitespace {
  white-space: pre-wrap;
  
}
