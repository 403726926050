.formInformationAccuracyDisclaimer {
  font-size: 1rem;
}

.formInformationAccuracyDisclaimer label {
  font-size: 1rem;
}

.formInformationAccuracyDisclaimer ul {
  list-style-type: circle;
}
