.float-save-container {
  position: fixed;
  top: 13vh;
  right: 3vw;
  font-size: 30px;
  cursor: pointer;
}

@media (max-height: 400px) {
  .float-save-container {
    top: 55px;
  }
}

@media (min-height: 715px) {
  .float-save-container {
    top: 90px;
  }
}


@media (max-width: 550px) {
  .float-save-container {
    right: 2vw;
  }
}
