.form-check {
  padding-left: 2rem !important;
}
.form-check-input {
  margin-top: 0.7rem !important;
  transform: scale(1.9, 1.9) !important;
  flex-shrink: 0 !important;
}
.rbt-menu {
  width: 476px !important; 
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 50px) !important;
}