h6 {
    margin: 1em 0 0.7em 0;
    font-weight: normal;
    position: relative;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.6);
    font-size: 28px;
    line-height: 40px;
    background: #355681;
    background: rgba(53, 86, 129, 0.8);
    border: 1px solid #fff;
    padding: 5px 15px;
    color: white;
    border-radius: 0 10px 0 10px;
    box-shadow: inset 0 0 5px rgba(53, 86, 129, 0.5);
    font-family: "Open Sans", sans-serif;
}

.formHeader {
    text-align: center;
    width: 100%;
}

.col {
    min-width: 100px;
}

.maxWidth850 {
    max-width: 850px;
}

.maxWidth550 {
    max-width: 550px;
}

.maxWidth500 {
    max-width: 500px;
}

.maxWidth400 {
    max-width: 400px;
}

.maxWidth300 {
    max-width: 300px;
}

.maxWidth200 {
    max-width: 200px;
}

.maxWidth150 {
    max-width: 150px;
}

.maxWidth100 {
    max-width: 100px;
}

.flexRight {
    display: flex;
    flex-direction: row-reverse;
}

.alignRight {
    text-align: right;
}

.formSectionHeader {
    text-align: left;
}

.requiredFieldNotice {
    text-align: right;
    font-style: italic;
    width: 100%;
}

.formTextArea {
    margin-bottom: 2vh;
}

.formLabelText {
    margin-top: 0.5rem;
}

.formLabelText .form-check-label {
    padding-top: 5% !important;
}

.formLabelText .form-check {
    padding-top: 10px !important;
}

.formLabelText .form-check-input {
    margin-left: -25px !important;
}

.formLabelText,
.alert {
    font-size: min(4vw, 20px);
    margin-bottom: 0.5rem;
}

.clientReportedRadioCategory {
    margin-top: "50px";
}

.form-check-label {
    margin-top: 0.55rem;
    margin-left: 0.5rem;
}

.managementPlanCheck {
    margin-left: 1rem;
}

.separator {
    margin: 3rem 0 3rem 0;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
}

.checkboxBeforeButton {
    margin-right: 7%;
}


.invalid {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

@media only screen and (max-width: 700px) {

    .formLabelText,
    .alert {
        margin-bottom: 0.5vh;
    }

    .alert,
    .alertContainer {
        padding: 0.5vw;
        font-size: 3.5vw;
    }

    .formLabelText .form-check-input {
        margin-top: max(2vw, 6px) !important;
        height: 12px;
    }

    .intakeFormContainer .btn {
        font-size: calc(12px + 1vw);
    }

    .intakeFormContainer .h5 {
        font-size: calc(100% + 2.3vw) !important;
    }

    .separator {
        margin: 3vh 0 3vh 0;
    }

    .form-check-label {
        font-size: calc(12px + 1vw);
    }
}