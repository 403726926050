.ToastError {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
#ToastErrorHeader {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}
.ToastInfo {
  background-color: #d1ecf1;
  border-color: #0c5460;
  color: #0c5460;
}
#ToastInfoHeader {
  background-color: #d1ecf1;
  border-color: #0c5460;
  color: #0c5460;
}
.ToastContainer {
  z-index: 200000 !important;
  position: fixed;
  top: 4em;
  right: 1.5em;
}
