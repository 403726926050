.AuditList .searchBar {
    z-index: 0;
}

.AuditList .stickyHeader {
    z-index: 0;
}

.AuditList .stickyHeader thead th {
    z-index: 0;
}

#search-bar-0 {
    border: 1px solid #ced4da;
}

.AuditList .form-label {
    font-size: 16px;
    font-weight: 600;
}