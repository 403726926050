#footer-container {
  position: fixed;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-end;

  max-height: 64px;
  height: 6vh;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #f9f9f9;
  max-width: none; /*Overwrites bootstrap grid property*/
}

.footer-copyright {
  flex-grow: 1;
}

.footer-feedback,
.footer-copyright,
.footer-privacy {
  padding-left: 2vw;
  font-size: max(min(calc(20% + 1.5vw), 1.2rem), 0.9rem);
  /* identical to box height, or 120% */
  color: #646464;
}
