#header-container {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-height: 85px;
	min-height: 50px;
	height: 12vh;
	left: 0px;
	right: 0px;
	top: 0px;
	max-width: none; /*Overwrites bootstrap grid property*/
	background: #f9f9f9;
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}
.HeaderSpacer {
	position: relative;
	max-height: 85px;
	height: 12vh;
	left: 0px;
	right: 0px;
	top: 0px;
	max-width: none; /*Overwrites bootstrap grid property*/
}
.BannerMessage {
	position: relative;
	max-height: 60px;
	height: 6vh;
	left: 0px;
	right: 0px;
	top: 0px;
	max-width: none; /*Overwrites bootstrap grid property*/
}
.BannerMessage .alertMessage {
	text-align: center !important;
}

.header-logo {
	align-self: center;
	padding-left: 3vw;
}
img {
	height: max(min(5vw, 48px), 30px);
	width: auto;
}

img:hover {
	cursor: pointer;
}

.header-title {
	text-align: center;
	align-self: center;
	font-size: calc(20% + 2vw);
}

.header-login {
	padding-right: 3vw;
	font-size: calc(20% + 1.5vw);
	color: #646464;
}

.header-login .btn {
	/* max-width: 300px !important; */
	min-width: 200px !important;
	min-height: 30px !important;
	padding: 0px;
	/* width: 20vw; */
	font-size: calc(70%);
}

.header-btn > div > .dropdown-item {
	font-size: 30px;
	padding: 0.5vh 2vw 0.5vh 2vw !important;
}

.dropdown-menu {
	min-width: 100px !important;
	z-index: 300000 !important;
}

.spinner {
	align-content: center;
	max-height: 3vh;
	max-width: 3vh;
}

@media only screen and (max-width: 700px) {
	#header-container {
		padding-left: 3vw;
		padding-right: 3vw;
		height: 7vh;
	}
	.HeaderSpacer {
		height: 7vh;
	}

	.header-logo {
		padding-left: 0px;
	}
	img {
		width: 20vw;
		height: auto;
	}
	.header-title {
		padding: 0 1vw 0 1vw;
		font-size: 3vw;
	}

	.header-login {
		padding-right: 0px;
		font-size: calc(20% + 1.2vw);
	}
	.header-login .btn {
		padding: 0.5vh 1px 0.5vh 1px !important;
		font-size: calc(50% + 1.5vw);
		height: 4vh;
		width: 30vw;
		min-width: 0px !important;
	}
	.dropdown-item {
		font-size: 5vw;
		padding: 0.5vh 2vw 0.5vh 2vw !important;
	}
}
