@import "../../form.css";

.intakeFormContainer {
  padding: 4vh 20px;
  min-width: 100px;
  max-width: 960px;
  margin-bottom: 4vh;
  /*Stops footer from overlapping*/
}

.intakeFormContainer .form-control,
.intakeFormContainer .form-select,
.intakeFormContainer .form-section-check,
.intakeFormContainer .form-check {
  font-size: 70%;
}

.intakeFormContainer .form-check-input {
  border: 1px solid #343a40;
}

.intakeFormContainer .form-group {
  margin-bottom: 4vh;
}

@media only screen and (max-width: 700px) {
  .intakeFormContainer .form-group {
    margin-bottom: 4vh;
  }

  .intakeFormContainer .btn {
    font-size: calc(12px + 1vw);
  }

  .intakeFormContainer .h5 {
    font-size: calc(100% + 2.3vw) !important;
  }
}